import React from "react";
import { RichTextInput } from "ra-input-rich-text";
import { styled } from "@mui/material";

// wrapping RichTextInput allows us to inject custom styles
// this was created for adding indentation, but can be expanded upon

let indents = '';
for(let i = 1; i < 9; i++) {
  indents = `${indents}\n&&& [data-indent="${i}"] { margin-left: ${i * 2}rem; }`;
}

const InputWrapper = styled('div')(indents)

const CustomRichTextInput = (props) => <InputWrapper><RichTextInput {...props}/></InputWrapper>;

export default CustomRichTextInput;
