import React from 'react';
import { SaveButton, Toolbar, useRecordContext } from 'react-admin';
import { withStyles } from '@mui/styles';
import CustomDeleteButton from "../CustomDeleteButton";

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)(({ title = 'name', displayDelete = true, ...props }) => {
  const { resource } = props;
  const record = useRecordContext();

  const buildItem = (record, resource) => {
    if (resource === 'AdminUser') {
      return `${record.firstName} ${record.lastName}`;
    } else {
      return record[title] ? record[title] : record.id;
    }
  };

  return (
    <Toolbar {...props}>
      <SaveButton />
      {displayDelete && <CustomDeleteButton item={buildItem(record, resource)} />}
    </Toolbar>
  );
});

export default CustomToolbar;
