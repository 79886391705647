import React from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';
import { Helmet } from 'react-helmet';
import { lockDown } from 'utils/auth';
import { isTemplateAdmin, isUCPAdmin } from 'utils/roles';
import BadgeTemplateFilter from './BadgeTemplateFilter';

const BadgeList = props => (
  <List title='Badges' {...props} sort={{ field: 'name', order: 'ASC' }} filters={<BadgeTemplateFilter />}>
    <Datagrid>
      <Helmet>
        <title>{props?.options?.label}</title>
      </Helmet>
      <TextField source='badge.name' />
      <TextField source='status' />
      <TextField source='badge.number' />
      <TextField source='badge.level' />
      <TextField label='Total Uses' source='stats.totalUses' />
      <EditButton />
    </Datagrid>
  </List>
);

export default lockDown(BadgeList, [isUCPAdmin, isTemplateAdmin]);
