import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  required,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceField,
  AutocompleteInput,
  AutocompleteArrayInput,
  downloadCSV,
  useRecordContext, DateInput,
} from 'react-admin';
import CustomToolbar from '../CustomToolbar';
import ReceiptIcon from '@mui/icons-material/Receipt';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import UtcDateField from 'components/UtcDateField';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { makeStyles } from '@mui/styles';
import jsonExport from 'jsonexport/dist';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

const isRequired = [required()];

export const ContractIcon = ReceiptIcon;

const useStyles = makeStyles({
  formContainer: {
    '& .ra-input-expirationDate .MuiFormControl-root': {
      zIndex: 2,
    },
    '& .MuiCard-root': {
      overflow: 'visible',
    },
  },
});

const ContractFilter = props => (
  <SavelessFilter {...props}>
    <ReferenceInput
      label='Contracting Agency'
      source='contractingAgency'
      reference='AdminAgency'
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      label='Member Agency'
      source='memberAgencies'
      reference='AdminAgency'
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput label='Market' source='markets' reference='AdminMarket' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
  </SavelessFilter>
);

// https://marmelab.com/react-admin/List.html#exporter
const exporter = (records, fetchRelatedRecords) => {
  Promise.all([
    fetchRelatedRecords(records, 'contractingAgency', 'AdminAgency'),
    fetchRelatedRecords(records, 'memberAgencies', 'AdminAgency'),
    fetchRelatedRecords(records, 'markets', 'AdminMarket'),
  ]).then(([contractingAgencies, memberAgencies, markets]) => {
    const data = records.map(record => ({
      ...record,
      contractingAgency: contractingAgencies[record.contractingAgency].name,
      memberAgencies: record.memberAgencies.map(a => memberAgencies[a].name),
      markets: record.markets.map(a => markets[a].name),
    }));

    return jsonExport(data, {}, (err, csv) => {
      downloadCSV(csv, 'AdminContract');
    });
  });
};

const ContractList = props => (
  <List
    title='Contracts'
    filters={<ContractFilter />}
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    exporter={exporter}
  >
    <Datagrid bulkActionButtons={false}>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <ReferenceField
        label='Contracting Agency'
        source='contractingAgency'
        reference='AdminAgency'
        link={false}
        sortable={false}
      >
        <TextField source='name' />
      </ReferenceField>
      <UtcDateField label='Expiration Date' source='expirationDate' />
      <EditButton />
    </Datagrid>
  </List>
);

export const SecuredContractList = lockDown(ContractList, [isUCPAdmin]);

const ContractTitle = () => {
  const record = useRecordContext();
  return <span>Contract: {record ? `${record.id}` : ''}</span>;
};

const ContractEdit = props => {
  const classes = useStyles();

  return (
    <Edit title={<ContractTitle />} mutationMode='pessimistic' className={classes.formContainer} {...props}>
      <SimpleForm toolbar={<CustomToolbar displayDelete={false}/>}>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <ReferenceInput
          label='Contracting Agency'
          source='contractingAgency'
          reference='AdminAgency'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText='name' />
        </ReferenceInput>

        {/*Is Required*/}
        <DateInput label='Expiration Date' source='expirationDate' validate={required()} />
        <ReferenceArrayInput
          label='Work Sector'
          source='markets'
          reference='AdminMarket'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label='Member Agencies'
          source='memberAgencies'
          reference='AdminAgency'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const SecuredContractEdit = lockDown(ContractEdit, [isUCPAdmin]);

const ContractCreate = props => {
  const classes = useStyles();

  return (
    <Create title='Create a Contract' className={classes.formContainer} {...props}>
      <SimpleForm>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <ReferenceInput
          label='Contracting Agency'
          source='contractingAgency'
          reference='AdminAgency'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText='name' validate={isRequired} />
        </ReferenceInput>

        {/*Is Required*/}
        <DateInput source='expirationDate' label='ExpiratiFon Date' />
        <ReferenceArrayInput
          label='Work Sector'
          source='markets'
          reference='AdminMarket'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectArrayInput optionText='name' validate={isRequired} />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label='Member Agencies'
          source='memberAgencies'
          reference='AdminAgency'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const SecuredContractCreate = lockDown(ContractCreate, [isUCPAdmin]);
