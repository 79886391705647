import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  required,
  minLength,
  AutocompleteInput,
  FormDataConsumer,
  BooleanInput,
  ImageInput,
  ImageField,
  downloadCSV,
  useRecordContext,
  Labeled,
} from 'react-admin';
import CustomToolbar from '../CustomToolbar';
import BusinessIcon from '@mui/icons-material/Business';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import jsonExport from 'jsonexport/dist';
import { MAX_FILE_SIZE } from 'utils/consts';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

const validateRequiredText = [required(), minLength(2)];

// Converted to Mime types '.jpg,.jpeg,.gif,.png'
export const ALLOWED_AGENCY_FILE_TYPES = ['image/jpeg', 'image/gif', 'image/png'];

export const AgencyIcon = BusinessIcon;

const AgencyTitle = () => {
  const record = useRecordContext();
  return <span>Member Agency {record ? `"${record.name}"` : ''}</span>;
};

const AgencyFilter = props => (
  <SavelessFilter {...props}>
    <TextInput label='Search' name='q' source='q' alwaysOn />
    <ReferenceInput label='State' name='state' source='state' reference='AdminState' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput label='Region' name='region' source='region' reference='AdminRegion' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <BooleanInput label='Badges Enabled' name='hasBadges' source='hasBadges' />
  </SavelessFilter>
);

// https://marmelab.com/react-admin/List.html#exporter
const exporter = (records, fetchRelatedRecords) => {
  Promise.all([
    fetchRelatedRecords(records, 'state', 'AdminState'),
    fetchRelatedRecords(records, 'region', 'AdminRegion'),
  ]).then(([states, regions]) => {
    const data = records.map(record => ({
      ...record,
      state: states[record.state].name,
      region: regions[record.region].name,
    }));

    return jsonExport(data, {}, (err, csv) => {
      downloadCSV(csv, 'AdminAgency');
    });
  });
};

const AgencyList = props => (
  <List
    title='Member Agencies'
    {...props}
    filters={<AgencyFilter />}
    sort={{ field: 'name', order: 'ASC' }}
    exporter={exporter}
  >
    <Datagrid bulkActionButtons={false}>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='name' />
      <ReferenceField label='State' source='state' reference='AdminState' sortable={false}>
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField label='Region' source='region' reference='AdminRegion' sortable={false}>
        <TextField source='name' />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const SecuredAgencyList = lockDown(AgencyList, [isUCPAdmin]);

const AgencyEdit = props => (
  <Edit title={<AgencyTitle />} mutationMode='pessimistic' {...props}>
    <SimpleForm toolbar={<CustomToolbar displayDelete={false}/>}>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput name='name' source='name' validate={validateRequiredText} />
      <ReferenceInput label='State' name='state' source='state' reference='AdminState' sort={{ field: 'name', order: 'ASC' }}>
        <SelectInput optionText='name' />
      </ReferenceInput>

      <BooleanInput name='hasEngage' source='hasEngage' label='Engage Access' defaultValue={false} />
      <BooleanInput name='hasBadges' source='hasBadges' label='Enable Badges' defaultValue={false} />

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceInput
            label='Region'
            source='region'
            reference='AdminRegion'
            filter={{ state: formData.state }}
            sort={{ field: 'name', order: 'ASC' }}
            {...rest}
          >
            <AutocompleteInput optionText='name' />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {props => (
          <Labeled label='Logo File Name'>
            <TextField source='attachment.fileName' {...props} />
          </Labeled>
        )}
      </FormDataConsumer>
      <ImageInput
        name='logoUpload'
        source='logoUpload'
        label='Update Logo'
        accept={ALLOWED_AGENCY_FILE_TYPES.join(',')}
        multiple={false}
        maxSize={MAX_FILE_SIZE}
        labelSingle='Drop a file here, or click to select a file to attach.'
        helperText='Images that are 180 x 60 will look best on certificates.'
      >
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const SecuredAgencyEdit = lockDown(AgencyEdit, [isUCPAdmin]);

const AgencyCreate = props => (
  <Create title='Create a Member Agency' {...props}>
    <SimpleForm>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput name='name' source='name' validate={validateRequiredText} />
      <ReferenceInput label='State' name='state' source='state' reference='AdminState' sort={{ field: 'name', order: 'ASC' }}>
        <SelectInput optionText='name' />
      </ReferenceInput>

      <BooleanInput name='hasEngage' source='hasEngage' label='Engage Access' defaultValue={false} />
      <BooleanInput name='hasBadges' source='hasBadges' label='Enable Badges' defaultValue={false} />

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceInput
            label='Region'
            source='region'
            reference='AdminRegion'
            filter={{ state: formData.state }}
            sort={{ field: 'name', order: 'ASC' }}
            {...rest}
          >
            <AutocompleteInput optionText='name' />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <ImageInput
        name='logoUpload'
        source='logoUpload'
        label='Logo'
        accept={ALLOWED_AGENCY_FILE_TYPES.join(',')}
        multiple={false}
        maxSize={MAX_FILE_SIZE}
        labelSingle='Drop a file here, or click to select a file to attach.'
      >
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export const SecuredAgencyCreate = lockDown(AgencyCreate, [isUCPAdmin]);
